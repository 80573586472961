export const getUser = () => {
  const userDomain = sessionStorage.getItem('userDomain');
  if (userDomain){
      
        return userDomain;
  }
  else return null;
};
 
// return the token from the session storage
export const getToken = () => {
  return sessionStorage.getItem('token') || null;
};
 
// remove the token and user from the session storage
export const removeUserSession = () => {
  sessionStorage.removeItem('userDomain');
};
 
// set user data to the session storage
export const setUserSession = ( userDomain) => {
  sessionStorage.setItem('userDomain', JSON.stringify(userDomain));
  
};

