import React, { useState } from 'react';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { getUser, removeUserSession } from './utilities/Common';

export const AppProfile = (props) => {

    const [expanded, setExpanded] = useState(false);
    const u = getUser();  
    
    const onClick = (id, event) => {
        //console.log(id);
        const doLogout =() => { 
            removeUserSession();
            window.location ="/";
        };
        
        if (id === 'user') {
            if (u){
                //console.log("We have a valid user in session");
                //let u = ${getUser}
                //var domu=JSON.parse(u);                
                setExpanded(prevState => !prevState);
                //console.log("domain: ",domu.contactName);
                event.preventDefault();
            }
        }else{
            //props.history.push('/'+id);
            if (u){
                console.log("should route to "+id,props.history);
                if ( id==='Logout'){
                    {doLogout()};
                }else{
                    if (props.history){
                        props.history.push('/'+id);
                    }
                }
            }
            
        }
    };

    return (
            <div className="layout-profile">
                <div>
                    <img src="assets/layout/images/profile.png" alt="Profile" />
                </div>
                <button className="p-link layout-profile-link" onClick={(e) => onClick('user',e)}>
                    <span className="username">{u && JSON.parse(u).contactName}</span>
                    <i className="pi pi-fw pi-cog" />
                </button>
                <CSSTransition classNames="p-toggleable-content" timeout={{enter: 1000, exit: 450}} in={expanded} unmountOnExit>
                    <ul className={classNames({'layout-profile-expanded': expanded})}>
                        <li><button type="button" className="p-link" onClick={(e) => onClick('Account',e)}><i className="pi pi-fw pi-user" /><span>Account</span></button></li>
                        <li><button type="button" className="p-link" onClick={(e) => onClick('Logout',e)}><i className="pi pi-fw pi-power-off" /><span>Logout</span></button></li>
                    </ul>
                </CSSTransition>
            </div>
            );

}
