import React, { useState } from 'react';
//import { Route, useHistory } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import axios from 'axios';
import { getUser, setUserSession, removeUserSession } from './utilities/Common';

export const Login = (props) => {
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    //console.log("Constructor GBEMI: ", props);
    // handle button click of login form
    const handleLogin = () => {
        setError(null);
        setLoading(true);
        //props.history.push('/dashboard');
        let _userSession = {
            id: username,
            pwd: password
        };
        //get('assets/demo/data/products-small.json').then(res => res.data.data);
        axios.post('http://34.123.205.62:7773/api/v1/mbsse/users/signin',
                {username: username,
                    password: password
                }).then(response => {
            setLoading(false);
            setUserSession(response.data);
            //JSON.parse(response.data)
            if (response.data.userRole.includes('admin')){
                props.history.push('/dashboard');
            }else{
                props.history.push('/deactivate');
            }
            props.onToggleMenu({
                originalEvent:null,
                item: null
            });
            
        }).catch(error => {
            setLoading(false);
            if (error.response)
                setError(error.response.data.message);
            else
                setError("Something went wrong. Please try again later.");
        });
    };


    const warning = () => {
        return (
                <div className="p-grid p-align-stretch vertical-container">
                    <div className="p-col-12 ">
                        <div className="card"> 
                            <h5>Warning</h5>
                            <p>This entire website is governed by Sierra Leone and international legislation on copyright, trademark and database law and intellectual property in general, both 
                            as they are applicable to its form (editorial choices, layout, topics, means for accessing data, display, etc.), and to its content (text, images, etc.). 
                            The contents displayed on this website are of the exclusive property of Digitalle and the Contributors, respectively. 
                            You also undertake not to use this content for illegal purposes and not to allow anyone to do so.</p>                            
                            <p>By way of exception, some of the content (text, images) is the property of their respective authors.
                            
                            Any reproduction, representation, diffusion or re-diffusion, in whole or in part, of the contents of this website on any medium or by any means 
                            whatsoever (including, but not limited to, caching, framing), as well as any sale, resale, retransmission or any other act tending to make such contents 
                            available to any third party in any manner whatsoever shall be prohibited,  except preliminary written authorization of the publication manager. 
                            Failure to comply with these restrictions shall constitute a forgery engaging the civil and criminal liability of the forger.
                            Only the paper reproduction is authorized under conditions of a use in strictly personal purposes, a respect for the integrity of the reproduced documents, 
                            or in short quotation with clear and readable mention of the source, 
                            for example under the following form "Extracts from the site digitalle.com.sl. All rights reserved Digitalle."
                            The trademarks quoted on the site are protected: their reproduction or use is forbidden.</p>
                        </div>
                    </div>
                </div>
                );
    };

    const  loginframe = () => {
        return (
                <div className="p-d-flex p-jc-evenly">
                    <div className="card">		    
                        <div className="p-field p-grid">
                            <label htmlFor="username" className="p-col-fixed" style={{width: '100px'}}>Username</label>
                            <div className="p-col">
                                <InputText id="username" value={username} onChange={(e) => setUserName(e.target.value)}></InputText>
                            </div>	    	    
                        </div>
                        <div className="p-field p-grid">
                            <label htmlFor="password" className="p-col-fixed" style={{width: '100px'}}>Password</label>
                            <div className="p-col">
                                <Password id="password" value={password} onChange={(e) => setPassword(e.target.value)} toggleMask />
                            </div>	    
                        </div>
                        <div><Button label="Submit" icon="pi pi-check" iconPos="right" onClick={(e) => handleLogin(e)}/></div>
                    </div>
                </div>
                );
    };

    return (
            <div className="p-grid p-align-stretch vertical-container">
                <div className="p-col-12">
                    <div className="box box-stretched">{loginframe()}</div>
                </div>
                <div className="p-col-12">
                    <div className="box box-stretched">{warning()}</div>
                </div>
            </div>
            );



};
