import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Route, useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { AppTopbar } from './AppTopbar';
import { AppFooter } from './AppFooter';
import { AppMenu } from './AppMenu';
import { AppProfile } from './AppProfile';
import { Login } from './Login';
import { Logout } from './Logout';
import { Financials } from './Financials';
import { Deactivate } from './Deactivate';
import { Activate } from './Activate';
import { Churn } from './Churn';

import { Dashboard } from './Dashboard';
import { getUser } from './utilities/Common';
import { Breaking} from './Breaking';
import { Users} from './Users';


import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import './layout/flags/flags.css';
import './layout/layout.scss';
import './App.scss';

const App = () => {
    let userLoggedIn = getUser();
    const layoutMode = 'static';
    const layoutColorMode='light';
    const [staticMenuInactive, setStaticMenuInactive] = useState(!userLoggedIn);
    const [overlayMenuActive, setOverlayMenuActive] = useState(!userLoggedIn);
    const [mobileMenuActive, setMobileMenuActive] = useState(!userLoggedIn);
    const inputStyle='outlined';
    const sidebar = useRef();
    
    
    
    const history = useHistory();

    let menuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, 'body-overflow-hidden');
        }
        else {
            removeClass(document.body, 'body-overflow-hidden');
        }
    }, [mobileMenuActive]);

    const onWrapperClick = (event) => {
        console.log(event.target.name,menuClick);
        if (!menuClick) {
            //setOverlayMenuActive(false);
            setMobileMenuActive(true);
        }
        menuClick = false;
    };

    const onToggleMenu = (event) => {
        menuClick = true;
        console.log("Toggle click",event,layoutMode);
        if ( getUser ){
            if (isDesktop()) {
                if (layoutMode === 'overlay') {
                    setOverlayMenuActive(prevState => !prevState);
                }
                else if (layoutMode === 'static') {
                    setStaticMenuInactive(prevState => !prevState);
                }
            }
            else {
                setMobileMenuActive(true);
            }
        }
        event.preventDefault();
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const onMenuItemClick = (event) => {
        console.log('Menu Clicked',event.item);
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    };

    const menu = [
        { label: 'Dashboard', icon: 'pi pi-fw pi-check-square', to: '/dashboard' },
        { label: 'WOTD Schedules', icon: 'pi pi-fw pi-home', to: '/home' },
        { label: 'Manage Users', icon: 'pi pi-fw pi-users', to: '/users' },
        { label: 'Manage subscripton', icon: 'pi pi-fw pi-heart', to: '/activate' },
        { label: 'Logout', icon: 'pi pi-fw pi-sign-out', to: '/logout' },
    ];

    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    };

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    };

    const isDesktop = () => {
        return window.innerWidth > 1024;
    };

    const isSidebarVisible = () => {
        if (isDesktop()) {
            if (layoutMode === 'static')
                return !staticMenuInactive;
            else if (layoutMode === 'overlay')
                return overlayMenuActive;
            else
                return true;
        }

        return true;
    };

    const logo = layoutColorMode === 'dark' ? 'assets/layout/images/digi188.png' : 'assets/layout/images/digi188.png';

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': false
    });

    const sidebarClassName = classNames('layout-sidebar', {
        'layout-sidebar-dark': layoutColorMode === 'dark',
        'layout-sidebar-light': layoutColorMode === 'light'
    });

    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            <AppTopbar onToggleMenu={onToggleMenu} />

            <CSSTransition classNames="layout-sidebar" timeout={{ enter: 200, exit: 200 }} in={isSidebarVisible()} unmountOnExit>
                <div ref={sidebar} className={sidebarClassName} onClick={onSidebarClick}>
                    <div className="layout-logo" style={{cursor: 'pointer'}} onClick={() => history.push('/home')}>
                        <img alt="Logo" src={logo} />
                    </div>
                    <AppProfile />
                    <AppMenu model={menu} onMenuItemClick={onMenuItemClick} />
                </div>
            </CSSTransition>

            <div className="layout-main">
                <Route path="/" exact component={Login}  />
                <Route path="/users" component={Users} />
                <Route path="/activate" component={Activate} />
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/home" component={Breaking} />
                <Route path="/logout" component={Logout}/>
            </div>

            <AppFooter />

        </div>
    );

}

export default App;
