import React, { useState,useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { NewsService } from './service/NewsService';
import { Dropdown } from 'primereact/dropdown';


export const Activate = () => {
    const [msisdn, setMsisdn] = useState('');
    const [maid, setMaid] = useState(0);
    const [firstSeen, setFirstSeen] = useState('');
    const [level, setLevel] = useState('');
    const [nickname, setNickname] = useState('');
    const [found, setFound]=useState(false);
    const levels =[1,2,3];
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const newsSvc = new NewsService();
    const showWait = () => {
        return ( <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8"  animationDuration=".5s"/>);
    };
    
    
    const onLevelChange = (e) => {        
        setLevel(e.value);
    };
    
    const lookup = () =>{
        setLoading(true);
        newsSvc.getSubByMsisdn(msisdn).then(data => { 
            setFound(true);
            setLoading(false);
            console.log(data);
            setFirstSeen(data.firstseen);
            setLevel(data.handle4);
            setNickname(data.handle1);
            setMaid(data.maid);
            toast.current.show({ severity: 'success', summary: 'success', detail: 'Subscription retrieved', life: 3000 });
        }).catch(error => {
            console.log(error);
            setLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'No subscription exists for this number', life: 3000 });
        });
        
    };
    
    const submit = () =>{
        setLoading(true);
        newsSvc.updateUser(nickname,level,msisdn).then(response => {
            setFound(false);
            setLoading(false);
            console.log(response.data);
            setFirstSeen('');
            setNickname('');
            setMsisdn('');
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Subscription updated successfully!', life: 3000 });
        }).catch(error => {
            console.log(error);
            setLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error while trying to update subscription. Please try again later', life: 3000 });
        });
        
        
    };
    
    return (
            <div className="p-d-flex p-jc-evenly">
                <div className="p-col-8 p-md-4">
                    <div className="card p-fluid">
                    <Toast ref={toast} />
                        <h5>Manage subscribers</h5>
                        <div className="p-inputgroup">
                            <InputText id="msisdn" type="text" placeholder="MSISDN(232XXXXXXXX)" onInput={(e) => setMsisdn(e.target.value)}/>
                            <Button icon="pi pi-search" className="p-button-warning" onClick={lookup}></Button>
                        </div>
                        <div>{loading && showWait()}</div>
                        <div className="p-field">
                            <label htmlFor="firstSeen">Activation date</label>
                            <InputText id="firstSeen" type="text" value={firstSeen} disabled="true"/>
                        </div>
                        <div className="p-field">
                            <label htmlFor="lastSeen">Nickname</label>
                            <InputText id="lastSeen" type="text" value={nickname} onInput={(e) => setNickname(e.target.value)}/>
                        </div>
                        <div className="p-field">
                            <label htmlFor="level">Level</label>
                            <Dropdown id="level" value={level} options={levels} onChange={onLevelChange}/>  
                        </div>
                        <Button label="Submit" onClick={submit}></Button>
                    </div>
                </div>
            </div>
            )
};
