import React from 'react';

export const AppFooter = () => {

    return (
        <div className="layout-footer">
            <span className="footer-text" style={{ 'marginRight': '5px' }}></span>
            <img src="assets/layout/images/digi188.png" alt="Logo" width="100" />
            <span className="footer-text" style={{ 'marginLeft': '5px' }}>All rights reserved</span>
        </div>
    );
}
