import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Fieldset } from 'primereact/fieldset';
import { Tooltip } from 'primereact/tooltip';
import {Checkbox} from 'primereact/checkbox';
import { NewsService } from './service/NewsService';

export const Users = () => {

    let emptyItem = {
        userID: '',
        userPassword: '',
        userRole: [],
        contactName: ''
    };
    const [creating, setCreating] = useState(false);
    const [items, setItems] = useState(null);
    const [itemDialog, setItemDialog] = useState(false);
    const [itemDialog1, setItemDialog1] = useState(false);
    const [editing, setEditing] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [item, setItem] = useState(emptyItem);
    const [selectedItems, setSelectedItems] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    
    const toast = useRef(null);
    const dt = useRef(null);
    const newsSvc = new NewsService();
    
    useEffect(() => {
        newsSvc.getAllUsers().then(data => { 
            setItems(data);
        });        
    }, []);



const onUserLevelChange = (e) => {
    let _prd={ ...item };
    let selectedLevels =_prd[`userRole`];
    if(e.checked)
        selectedLevels.push(e.value);
    else
        selectedLevels.splice(selectedLevels.indexOf(e.value), 1);
    
    _prd[`userRole`]=selectedLevels;
    setItem(_prd);
}





    const formatNumber = (value) => {
        return value.toLocaleString('en-US');
    };

    const openNew = () => {
        setItem(emptyItem);
        setCreating(true);
        setSubmitted(false);
        setItemDialog1(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setItemDialog(false);
        setItemDialog1(false);
        setCreating(false);
        setEditing(false);
    };


    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    };

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    };


    const saveProduct = () => {
        let _items = [...items];
        let _item = { ...item };
        if ( editing ){
            // update API Call
            console.log("Going to edit ...");
            
        }else{
            // Add API call
            console.log("going to create a new 1");
            _item.userID = item.userID;
            _item.userRole=item.userRole;
            _item.contactName=item.contactName;
            _item.userPassword='';
            _items.push(_item);
            newsSvc.updateUser(_item);
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'New user created with default password', life: 3000 });            
            
        }
        setItems(_items);
        setItemDialog(false);
        setItemDialog1(false);
        setItem(emptyItem);        
        setSubmitted(true);
        setCreating(false);
        setEditing(false);
    };

    const editProduct = (item) => {
        setItem({ ...item });
        setEditing(true);
        setItemDialog(true);
    };

    const confirmDeleteProduct = (item) => {
        setItem(item);
        setDeleteProductDialog(true);
    };

    const deleteProduct = () => {
        let _items = items.filter(val => val.wid !== item.wid);
        setItems(_items);
        newsSvc.removeItemOfDay(item);
        setDeleteProductDialog(false);
        setItem(emptyItem);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Schedule Deleted', life: 3000 });
    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < items.length; i++) {
            if (items[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const createId = () => {
        let id = '';
        let prefix=new Date().toISOString().split('T')[0].replaceAll('-','');
        //let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let chars = '0123456789';
        for (let i = 0; i < 3; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return prefix+id;
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };


    const deleteSelectedProducts = () => {
        let _items = items.filter(val => !selectedItems.includes(val));
        setItems(_items);
        setDeleteProductsDialog(false);
        setSelectedItems(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Products Deleted', life: 3000 });
    };

    const onCategoryChange = (e) => {
        let _product = { ...item };
        _product['category'] = e.value;
        setItem(_product);
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...item };
        _product[`${name}`] = val;

        setItem(_product);
    };

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _product = { ...item };
        _product[`${name}`] = val;

        setItem(_product);
    };

    const onLevelChange = (e) => {
        let _prd={ ...item };
        _prd[`level`]=e.value;
        setItem(_prd);
    };
    
    const onDateChange = (e) => {
        let _prd={ ...item };
        _prd[`validFor`]=e.value;
        setItem(_prd);
    };
    
    const prettyOxford = (rowData ) =>{
        newsSvc.getOxfordPretty(rowData.wid).then( d =>{
            //console.log(d.proxyData.value);
            return d.proxyData.value;
        });
        
    };
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
            </React.Fragment>
        );
    };


    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">UserID</span>
                {rowData.userID}
            </>
        );
    }

    const prettyBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Definition</span>
                {prettyOxford(rowData)}
            </>
        );
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">ContactName</span>
                {rowData.contactName}
            </>
        );
    }

    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Roles</span>
                {rowData.userRole.toString()}
            </>
        )
    }

    

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteProduct(rowData)} tooltip="Revoke" disabled={rowData.userRole.includes('admin')}/>
                <Button icon="pi pi-eye-slash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteProduct(rowData)} tooltip="Reset Password" disabled={rowData.userRole.includes('admin')}/>
            </div>
        );
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Users</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const creatingUserID = () =>{
        return (
            <div className="p-field">
                <label htmlFor="userID">UserID</label>
                <InputText id="userID" value={item.userID} onChange={(e) => onInputChange(e, 'userID')}/>                            
            </div>
        );
    };
    
    const editingUserID = () =>{
        return (
            <div className="p-field">
                <label htmlFor="userID">UserID</label>
                <InputText id="userID" value={item.userID} disabled/>                            
            </div>
        );
    };
    
    
     
    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );
    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );
    const deleteProductsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate}></Toolbar>

                    <DataTable ref={dt} value={items} selection={selectedItems} onSelectionChange={(e) => setSelectedItems(e.value)}
                        dataKey="userID" paginator rows={10} rowsPerPageOptions={[10, 15, 20]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
                        globalFilter={globalFilter} emptyMessage="No items found." header={header}>
                        <Column field="userID" header="UserID" sortable body={codeBodyTemplate}></Column>
                        <Column field="contactName" header="Contact name" body={nameBodyTemplate}></Column>
                        <Column field="userRole" header="Roles" body={imageBodyTemplate}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={itemDialog} style={{ width: '450px' }} header="User" modal className="p-fluid"  footer={productDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="contactName">Contact Name</label>
                            <InputText id="contactName" value={item.contactName} onChange={(e) => onInputChange(e, 'contactName')}/>
                        </div>
                        <div className="p-field">
                            <label htmlFor="userID">UserID</label>
                            <InputText id="userID" value={item.userID} disabled/>                           
                        </div>
                        <Fieldset legend="User Roles">
                        <div className="p-field">
                             <Checkbox inputId="cbu" value="user"  checked={item.userRole.includes('user')}></Checkbox>
                             <label htmlFor="cbu" className="p-checkbox-label">user</label>
                        </div>
                        <div className="p-field">
                             <Checkbox inputId="cbw" value="wotd"  checked={item.userRole.includes('wotd')}></Checkbox>
                             <label htmlFor="cbw" className="p-checkbox-label">wotd</label>
                        </div>
                        <div className="p-field">
                             <Checkbox inputId="cba" value="admin"  checked={item.userRole.includes('admin')}></Checkbox>
                             <label htmlFor="cba" className="p-checkbox-label">admin</label>
                        </div>
                        </Fieldset>
                    </Dialog>

                    <Dialog visible={itemDialog1} style={{ width: '450px' }} header="User" modal className="p-fluid"  footer={productDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="contactName">Contact Name</label>
                            <InputText id="contactName" value={item.contactName} onChange={(e) => onInputChange(e, 'contactName')}/>
                        </div>
                        <div className="p-field">
                            <label htmlFor="userID">UserID</label>
                            <InputText id="userID" value={item.userID} onChange={(e) => onInputChange(e, 'userID')}/>                           
                        </div>
                        <Fieldset legend="User Roles">
                        <div className="p-field">
                             <Checkbox inputId="cbu" value="user"  checked={item.userRole.includes('user')} onChange={onUserLevelChange}></Checkbox>
                             <label htmlFor="cbu" className="p-checkbox-label">user</label>
                        </div>
                        <div className="p-field">
                             <Checkbox inputId="cbw" value="wotd"  checked={item.userRole.includes('wotd')} onChange={onUserLevelChange}></Checkbox>
                             <label htmlFor="cbw" className="p-checkbox-label">wotd</label>
                        </div>
                        <div className="p-field">
                             <Checkbox inputId="cba" value="admin"  checked={item.userRole.includes('admin')} onChange={onUserLevelChange}></Checkbox>
                             <label htmlFor="cba" className="p-checkbox-label">admin</label>
                        </div>
                        </Fieldset>
                    </Dialog>

                    <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {item && <span>Are you sure you want to delete <b>{item.userID}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteProductsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {item && <span>Are you sure you want to delete the selected items?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
