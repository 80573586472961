import React from 'react';
import { Button } from 'primereact/button';
import { removeUserSession } from './utilities/Common';

export const Logout = (props) => {
    removeUserSession();
    
    const handleLogin = () => {
       props.history.push('/');
    };


    const thanks = () => {
        return (
                <div className="card"> 
                    <h5>Thank you</h5>
                    <p>Thank you for using Digitalle MBSSE portal
                        You can please send us suggestions via mail me@digitalle.com.sl
                        blah</p>
                <div><Button label="Login" icon="pi pi-check" iconPos="right" onClick={(e) => handleLogin(e)}/></div>
                </div>
                );
    };

    return (
            <div className="p-grid p-align-stretch vertical-container">
                <div className="p-col-12">
                    <div className="box box-stretched">{thanks()}</div>
                </div>
            </div>
            );



};
