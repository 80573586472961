import axios from "axios";

export class NewsService {
  getItemsOfDay(day) {
    return axios
      .get("http://34.132.249.215:7773/api/v1/mbsse/wotd/get/list/" + day)
      .then((response) => response.data.data);
  }

  setNewItemOfDay(item) {
    return axios
      .post("http://34.132.249.215:7773/api/v1/mbsse/wotd/additem", {
        word: item.wordValue,
        day: item.validFor,
        level: item.level,
      })
      .then((response) => response.data.data);
  }

  removeItemOfDay(item) {
    return axios
      .delete(
        "http://34.132.249.215:7773/api/v1/mbsse/wotd/delete/id/" + item.wid
      )
      .then((response) => response.data);
  }

  getFinancialsOfToday() {
    return axios
      .get("http://34.132.249.215:7773/api/v1/mbsse/news/finance/today")
      .then((r) => r.data.data);
  }

  getFinancialsOfYesterday() {
    return axios
      .get("http://34.132.249.215:7773/api/v1/mbsse/news/finance/yesterday")
      .then((r) => r.data.data);
  }

  getFinancialsOfThisMonth() {
    return axios
      .get("http://34.132.249.215:7773/api/v1/mbsse/news/finance/thismonth")
      .then((r) => r.data.data);
  }

  getFinancialsOfLastMonth() {
    return axios
      .get("http://34.132.249.215:7773/api/v1/mbsse/news/finance/lastmonth")
      .then((r) => r.data.data);
  }

  getDashboardBandOne() {
    return axios
      .get("http://34.132.249.215:7773/api/v1/mbsse/stats/dash")
      .then((r) => r.data);
  }

  getDashboardRevGraph() {
    return axios
      .get("http://34.132.249.215:7773/api/v1/mbsse/stats/graphdash")
      .then((r) => r.data);
  }
  getOxfordPretty(item) {
    let u =
      "http://34.132.249.215:7773/api/v1/mbsse/wotd/get/id/" + item + "/pretty";
    return axios.get(u).then((r) => r.data);
  }

  getLookup(item) {
    let u = "http://34.132.249.215:7773/api/v1/mbsse/wotd/lookup/" + item;
    return axios.get(u).then((r) => r.data);
  }

  getAllUsers() {
    return axios
      .get("http://34.132.249.215:7773/api/v1/mbsse/users/get/all")
      .then((r) => r.data.data);
  }

  getAllContacts() {
    return axios
      .get("http://34.132.249.215:7773/api/v1/mbsse/contact/get/all")
      .then((r) => r.data.data);
  }

  getAllSubs() {
    return axios
      .get("http://34.132.249.215:7773/api/v1/mbsse/subs/get/all")
      .then((r) => r.data.data);
  }

  getSubByMsisdn(msisdn) {
    return axios
      .get("http://34.132.249.215:7773/api/v1/mbsse/subs/get/telco/" + msisdn)
      .then((r) => r.data);
  }

  updateUser(nickname, level, msisdn) {
    return axios
      .put("http://34.132.249.215:7773/api/v1/mbsse/subs/update", {
        nickName: nickname,
        level: level,
        msisdn: msisdn,
      })
      .then((response) => response.data);
  }
}
