import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import { ProgressSpinner } from 'primereact/progressspinner';
import { NewsService } from './service/NewsService';



export const Dashboard = () => {
    const [wotd,setWotd]=useState(null);
    const [loading,setLoading]=useState(true);
    const [monthData,setMonthData]=useState(null);
    const [everData,setEverData]=useState(null);
    const [trafficData,setTrafficData]=useState(null);
    
    useEffect(() => {
        const newsSvc = new NewsService();
        newsSvc.getDashboardBandOne().then(data =>{
            setWotd(data);
            var graph_month={
                labels:data.t10m.map(month=>month.word),
                datasets: [
                    {
                        label: 'Top ten searches of month',
                        data: data.t10m.map(month=>month.count),
                        fill: false,
                        backgroundColor: '#2f4860',
                        borderColor: '#2f4860'
                    }
                ]
            };
            var graph_ever={
                labels:data.t10a.map(ever=>ever.word),
                datasets:[
                    {
                        label: 'Top Ten Ever',
                        data: data.t10a.map(ever=>ever.count),
                        fill: false,
                        backgroundColor: '#00bb7e',
                        borderColor: '#00bb7e'
                    }
                ]
            };
            var graph_traffic={
              labels:data.traffic.map(t=>t.mid),
              datasets:[
                  {
                      label: 'Year Traffic Data',
                      data: data.traffic.map(t=>t.hits),
                      fill:false,
                      backgroundColor: '#00bb7e',
                      borderColor: '#00bb7e'
                  }
              ]
            };
            setMonthData(graph_month);
            setEverData(graph_ever);
            setTrafficData(graph_traffic);
            setLoading(false);
        });
       
    }, []);

    const formatNumber = (value) => {
        return value.toLocaleString('en-US');
    };

    if ( loading )
        return (<ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8"  animationDuration=".5s"/>);
        
        return (
            <div className="p-grid p-fluid dashboard">
                <div className="p-col-12 p-lg-3">
                    <div className="card summary">
                        <span className="title">Subscription</span>
                        <span className="detail">WOTD</span>
                        <span className="count visitors">{wotd && formatNumber(wotd.totalSubs)}</span>
                    </div>
                </div>
                <div className="p-col-12 p-lg-3">
                    <div className="card summary">
                        <span className="title">Today</span>
                        <span className="detail">New subscription</span>
                        <span className="count purchases">{wotd && formatNumber(wotd.todayNewSubs)}</span>
                    </div>
                </div>
                <div className="p-col-12 p-lg-3">
                    <div className="card summary">
                        <span className="title">Searches</span>
                        <span className="detail">Today</span>
                        <span className="count revenue">{wotd && formatNumber(wotd.daySearch)}</span>
                    </div>
                </div>

                <div className="p-col-12 p-lg-3">
                    <div className="card summary">
                        <span className="title">Searches</span>
                        <span className="detail">MTD</span>
                        <span className="count revenue">{wotd && formatNumber(wotd.monthSearch)}</span>
                    </div>
                </div>

                <div className="p-col-12 p-lg-6">
                    <div className="card">
                        <Chart type="bar" data={monthData} />
                    </div>
                </div>

                <div className="p-col-12 p-lg-6">
                    <div className="card">
                        <Chart type="bar" data={everData} />
                    </div>
                </div>

                <div className="p-col-12 p-lg-6">
                    <div className="card">
                        <Chart type="bar" data={trafficData} />
                    </div>
                </div>
            </div>

            
           );
}
